<script>
import "./plugins/rangeslider.css"
import "./plugins/rangeslider.js"
let $ = global.jQuery;

export default {
  props:{
    min:{default:0},
    max:{default:10},
    value: Number,
  },
  mounted(){
    setTimeout(() => {
      $(this.$el).rangeslider({
        polyfill: false,
        onInit: function () {
        },
        onSlide: (position, value)=>{
          this.$emit('input', value);
        },
        onSlideEnd: (position, value)=>{
          this.$emit("SlideEnd", value)
        }
      });
    }, 200);
  },
  watch:{
    value(value) {
      $(this.$el).val(value).rangeslider('update', true);
    }
  }
}
</script>

<template>
  <input :min="min" :max="max" type="text">
</template>