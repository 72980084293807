<script>
import Vue from 'vue'
import GlobalVue from '@libs/Global.vue'
import Gen from '@libs/Gen'
import RangeSliderVue from '@components/RangeSlider/RangeSlider.vue'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'

Vue.use(Croppa)
let $ = global.jQuery

export default {
  extends: GlobalVue,
  components:{
    RangeSlider: RangeSliderVue,
  },
  data: ()=>({
    show: false,
    imageUrl: "",
    callback: {},
    config: {},
    croppa: {},
    image: new Image(),
    brightness: 0,
    contrast: 0,
    onFilter: false,
    quality: 1,
    croppaWidth: 200,
    croppaHeight: 200,
  }),
  computed:{
    maxWidth(){
      if(this.config.max_width) return this.config.max_width
      if((this.config.imgsize||{})[""]) return this.config.imgsize[""][0]
      return 1920
    }
  },
  mounted: function(){
    global.ImageFilter = this
    $(window).resize(this.resizeInit);
  },
  methods: {
    imageContainer(){
      return $(this.$el).find('.image-container');
    },
    resizeInit(){
      this.croppaWidth = this.config.img_ratio * this.imageContainer().height();
      this.croppaHeight = this.imageContainer().height();
      if (this.croppaWidth > this.imageContainer().width()){
        this.croppaWidth =  this.imageContainer().width();
        this.croppaHeight = this.imageContainer().width() / this.config.img_ratio;
      }
    },
    startFilter(){
      this.onFilter = true;
      return this.onLoadImage(this.croppa.img);
    },
    onLoadImage(image) {
      this.c = document.createElement("canvas");
      this.cN = document.getElementById("IFCanvas");
      this.ctx = this.c.getContext("2d");
      this.ctxN = this.cN.getContext("2d");
      this.c.width = image.width
      this.c.height = image.height
      this.cN.width = image.width
      this.cN.height = image.height
      this.ctx.drawImage(image, 0, 0);
      this.ctxN.drawImage(image, 0, 0);
      this.quality = ((this.c.width > this.maxWidth ? this.maxWidth : this.c.width) / this.croppaWidth) || 1;
      if (this.config.img_ratio == 0) {
        this.config.img_ratio = this.c.width / this.c.height;
        $(window).trigger("resize");
      }
    },
    okFilter(){
      this.imageUrl = this.cN.toDataURL(this.config.fileType);
      this.croppa.img.src = this.imageUrl;
      this.croppa.refresh();
      this.onFilter = false;
      this.brightness = 0;
      this.contrast = 0;
    },
    filterImage(){
      // filter brightness
      var imgData = this.ctx.getImageData(0, 0, this.c.width, this.c.height);
      var d = imgData.data;
      for (var i = 0; i < d.length; i += 4) {
        d[i] += this.brightness;
        d[i + 1] += this.brightness;
        d[i + 2] += this.brightness;
      }
      // filter contrast
      var factor = (259 * (this.contrast + 255)) / (255 * (259 - this.contrast));
      for (i = 0; i < d.length; i += 4) {
        d[i] = factor * (d[i] - 128) + 128;
        d[i + 1] = factor * (d[i + 1] - 128) + 128;
        d[i + 2] = factor * (d[i + 2] - 128) + 128;
      }
      this.ctxN.putImageData(imgData, 0, 0);
    },
    loadCompressImage(imgUrl, callback){
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var img = new Image();
      img.crossOrigin = 'anonymous'

      img.onload = ()=>{
        if(img.width<this.maxWidth) return callback(img,imgUrl)
        canvas.width = this.maxWidth;
        canvas.height = canvas.width / (img.width/img.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        return callback(img, canvas.toDataURL(imgUrl.indexOf("png")>-1?"image/png":"image/jpeg", 80))
      }
      img.src = imgUrl
    },
    open(url, callback, config = {}){
      this.config = config
      $("html").addClass('noScroll');
      this.loadCompressImage(url, (img,imgUrl)=>{
        this.imageUrl = imgUrl
        this.callback = callback
        setTimeout(()=>{ this.show = true }, 100)
        setTimeout(()=>{ this.onLoadImage(img); this.resizeInit() },200)
        Gen.interval(()=>{ this.onLoadImage(img); this.resizeInit() },500,3000)
      })
    },
    onCancel(){
      this.show = false
      $("html").removeClass('noScroll');
    },
    onDone(){
      this.croppa.generateBlob((blob) => {
        var url = URL.createObjectURL(blob)
        this.callback(blob, url);
        this.show = false
        $("html").removeClass('noScroll');
      }, this.config.fileType);
    }
  },
  watch: {
    brightness(){ return Gen.delay(()=>{this.filterImage()}, 150) },
    contrast(){ return Gen.delay(()=>{this.filterImage()}, 150) },
    onFilter(value){
      this.imageContainer().css('bottom', value ? 200 : 100);
      $(window).trigger("resize");
    }
  }
}
</script>

<template>
  <div v-if="show" class="imagefilter">
    <a href="javascript:;" class="btnClose" @click="onCancel"><i class="ti-close"></i></a>
    <div class="image-container">
      <croppa v-show="!onFilter" v-model="croppa" :initial-image="imageUrl" :prevent-white-space="true" :disable-scroll-to-zoom="false" :show-remove-button="false" :quality="quality" :width="croppaWidth?croppaWidth:200" :height="croppaHeight"></croppa>
      <!-- @initial-image-loaded="onLoadImage(croppa.img)" -->
      <canvas v-show="onFilter" id="IFCanvas"></canvas>
    </div>
    <div class="control-container">
      <div class="text-center form-horizontal">
        <div v-show="!onFilter">
          <div class="md-no lg-no">
            <button class="btn btn-info" @click="croppa.rotate()"><span>Rotate</span></button>
            <button class="btn btn-info mr0" @click="startFilter()"><span>Filter</span></button>
            <div class="mb10"></div>
            <button class="btn btn-inverted btn-large" @click="onCancel"><span>Cancel</span></button>
            <button class="btn btn-default btn-large" @click="onDone"><span>Done</span></button>
          </div>
          <div class="m-no">
            <button class="btn btn-inverted btn-large" @click="onCancel"><span>Cancel</span></button>
            <button class="btn btn-info btn-large" @click="croppa.rotate()"><span>Rotate</span></button>
            <button class="btn btn-info btn-large" @click="startFilter()"><span>Filter</span></button>
            <button class="btn btn-default btn-large" @click="onDone"><span>Done</span></button>
          </div>
        </div>
        <div v-show="onFilter">
          <div class="form-group">
            <label class="lbfilter"><i class="icon-sun3"></i> Brightness</label>
            <div class="inpfilter" style="padding-top:10px">
              <RangeSlider v-model="brightness" :min="-50" :max="50"></RangeSlider>
            </div>
          </div><br>
          <div class="form-group">
            <label class="lbfilter"><i class="icon-contrast"></i> Contrast</label>
            <div class="inpfilter" style="padding-top:10px">
              <RangeSlider v-model="contrast" :min="-50" :max="50"></RangeSlider>
            </div>
          </div>
          <div class="save-buttons">
            <button class="btn btn-inverted btn-large" @click="onFilter=false"><span>Cancel</span></button>
            <button class="btn btn-default btn-large" @click="okFilter"><span>OK</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.imagefilter{
  background: #2d2d2df0;
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #fff;
}
.form-group{
  display: inline-block;
  width: 100%;
}
label{
  font-weight: 400;
  color: #fff;
}
.btnClose{
  position:absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
  i{
    color: #fff;
    font-size: 24px;
  }
}
#IFCanvas{
  max-width: 100%;
  max-height: 100%;
}
.image-container{
  position: absolute;
  top: 50px;
  bottom: 100px;
  left: 50px;
  right: 50px;
  text-align: center;
}
.control-container{
  position: absolute;
  bottom: 20px;
  left: 50px;
  right: 50px;
  text-align: center;
  label{
    text-align: right;
  }
}
.btn{
  border: 2px solid #f9f9f9;
  color: #f9f9f9;
  margin-right: 10px;
  &:last-child{
    margin-right: 0px;
  }
}
.btn-inverted{
  background: none;
  &:hover, &:focus{
    color: #fff;
  }
}
.jcrop-holder{
  margin: auto;
}
.croppa-container canvas {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px dashed #fff;
}
.lbfilter{
  width: 100px;
  display: inline-block;
  margin-right: 15px;
}
.inpfilter{
  width: 400px;
  max-width: 100%;
  display: inline-block;
}
</style>
